// import searchLogo from './search-svgrepo-com.svg'
import SearchIcon from '@mui/icons-material/Search';
import '../../assets/scss/style.scss';
const AutocompleteSearchBar = ({ query, setQuery }) => {
  return (
    <div
      style={{
        padding: "var(--4, 4px) var(--8, 8px)",
        borderRadius: "15px",
        display: "flex",
        justifyContent: "center",
        gap: "0.5em",
        alignItems: "center",
        borderRadius: "15px",
        background: "var(--black-5, rgba(28, 28, 28, 0.05))",
        width: "789px"
        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      <SearchIcon></SearchIcon>
      <input
        type="search"
        id="search"
        name="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder='Search'
        autocomplete="off"
        style={{
          width: "95%",
          border: 'none',
          zIndex: "1",
          background: "#f3f3f3",
        }}
      />
    </div>
  );
}
export default AutocompleteSearchBar;