import { useState, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, List, ListItemButton, ListItemIcon, ListItemText, Typography, Grid, Link } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import MegaMenuSection from './MegaMenuSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer, openDrawerRight } from 'store/slices/menu';
import AutocompleteSearchBar from '../../../views/lolly/SearchBar';
import QueryListTable from 'views/lolly/QueryListTable';
import useAuth from 'hooks/useAuth';
import User1 from 'assets/images/users/avatar.png';

// assets
import { IconMenu2 } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import sidebar from 'assets/images/icons-new/sidebar.svg';
import likes from 'assets/images/icons-new/likes.png';
import sun from 'assets/images/icons-new/sun.png';
import clock from 'assets/images/icons-new/clockCounterClockwise.png';
import bell from 'assets/images/icons-new/bell.png';

import { IconHelp, IconNews, IconBuildingStore } from '@tabler/icons';
import '../../../assets/scss/style.scss'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const [query, setQuery] = useState("");
  const { logout, user } = useAuth();

  const dispatch = useDispatch();
  const { drawerOpen, drawerOpenRight } = useSelector((state) => state.menu);

  const results = [
    {
      "id": 1,
      "keyword": "demand-driven",
      "popularity": 98,
      "fullquery": "demand-driven massa id lobortis"
    },
    {
      "id": 2,
      "keyword": "5th generation",
      "popularity": 76,
      "fullquery": "5th generation porta volutpat quam"
    },
    {
      "id": 3,
      "keyword": "holistic",
      "popularity": 30,
      "fullquery": "holistic mi integer ac neque"
    },
    {
      "id": 4,
      "keyword": "Exclusive",
      "popularity": 2,
      "fullquery": "Exclusive fermentum donec"
    },
    {
      "id": 5,
      "keyword": "neutral",
      "popularity": 82,
      "fullquery": "neutral amet justo morbi ut odio"
    },
    {
      "id": 6,
      "keyword": "Multi-layered",
      "popularity": 64,
      "fullquery": "Multi-layered eget tincidunt eget tempus vel"
    }
  ];

  return (


    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {/* Left Section: Username Icon */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <ProfileSection /> */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'black', fontSize: '14px' }}>
          <img src={User1} alt="" style={{ borderRadius: '50%', cursor: 'pointer', width: '30px', marginRight: '20px' }} />
          {/* {user?.email.split('@')[0]} */ 'Username'}
        </div>
      </Box>

      {/* Section with Two Icons */}
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 20 }}>
        <img src={sidebar} alt="Sidebar Icon" size="20px" onClick={() => dispatch(openDrawer(!drawerOpen))} />
        <img src={likes} alt="Likes Icon" size="20px" style={{ marginLeft: '25px' }} />
      </Box>

      {/* Search Bar Section */}
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <AutocompleteSearchBar query={query} setQuery={setQuery} />
        {/* <QueryListTable results={results} query={query} setQuery={setQuery} /> */}
      </Box>

      {/* Section with Four Icons */}
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
        <img src={sun} alt="Sun Icon" size="20px" style={{ marginLeft: '20px' }} />
        <img src={clock} alt="Clock Icon" size="20px" style={{ marginLeft: '20px' }} />
        <img src={bell} alt="Bell Icon" size="20px" style={{ marginLeft: '20px' }} />
        <img src={sidebar} alt="Sidebar Icon" size="20px" style={{ marginLeft: '20px' }} onClick={() => dispatch(openDrawerRight(!drawerOpenRight))} />
      </Box>
    </Box>
  );
};

export default Header;
