import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// import 'assets/scss/style.scss'

const RightSidebarList = ({ title, listItems }) => {
  return (
    <div>
      {/* Display the title if it is provided */}
      {title && (
        <Typography variant="h4" sx={{ mb: 2 }} className="list-item-text" >
          {title}
        </Typography>
      )}
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {listItems.map((item, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar>{item.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.primary} secondary={item.secondary} className="list-item-text" />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default RightSidebarList;
