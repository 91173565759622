import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import { openDrawerRight } from 'store/slices/menu'; // Make sure to create this slice
import Divider from '@mui/material/Divider';
import MenuList from './Sidebar/MenuList';
import RightSidebarList from './RightSidebarList';

import BugReportIcon from '@mui/icons-material/BugReport';
import PersonIcon from '@mui/icons-material/Person';
import SensorsIcon from '@mui/icons-material/Sensors';

const RightSidebar = ({ window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const { drawerOpenRight } = useSelector((state) => state.menu);
  const myFoldersInsights = [
    { icon: <BugReportIcon />, primary: 'You have a bug that needs to be fixed.', secondary: 'Just now' },
    { icon: <PersonIcon />, primary: 'New user registered.', secondary: '59 mins ago' },
    { icon: <BugReportIcon />, primary: 'You have a bug that needs to be fixed.', secondary: '12 hours ago' },
    { icon: <SensorsIcon />, primary: 'Andi Lane subscribed to you.', secondary: 'Today, 11:59 AM' },
  ];
  const myFoldersAudit = [
    { icon: <PersonIcon />, primary: 'Expert follower', secondary: 'Just now' },
    { icon: <PersonIcon />, primary: 'Expert follower', secondary: '59 mins ago' },
    { icon: <PersonIcon />, primary: 'Expert follower', secondary: '12 hours ago' },
    { icon: <PersonIcon />, primary: 'Expert follower', secondary: 'Today, 11:59 AM' },
  ];

  const drawer = useMemo(
    () => (
      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        {/* Add your menu or content here */}
        {/* <MenuList /> */}
        <RightSidebarList title="Insights" listItems={myFoldersInsights} />
        <RightSidebarList title="Audit Trail" listItems={myFoldersAudit} />
      </PerfectScrollbar>
    ),
    [matchUpMd]
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="right"
        open={drawerOpenRight}
        onClose={() => dispatch(openDrawerRight(!drawerOpenRight))}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: 'red',
            borderLeft: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerOpenRight && drawer}
      </Drawer>
    </Box>
  );
};

RightSidebar.propTypes = {
  window: PropTypes.object
};

export default memo(RightSidebar);
