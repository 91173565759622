import { useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import NewHeader from './Header/NewHeader.js';
import Sidebar from './Sidebar';
import NewSidebar from './NewSidebar';
import RightSidebar from './RightSidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import useNavigationEvent from 'hooks/useNavigationEvent';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'leftOpen' && prop !== 'rightOpen' })(
  ({ theme, leftOpen, rightOpen }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: leftOpen ? 0 : -(drawerWidth - 20),
      marginRight: rightOpen ? 0 : -(drawerWidth - 20),
      width: `calc(100% - ${leftOpen ? drawerWidth : 0}px - ${rightOpen ? drawerWidth : 0}px)`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: leftOpen ? '20px' : -(drawerWidth - 20),
      marginRight: rightOpen ? '20px' : -(drawerWidth - 20),
      width: `calc(100% - ${leftOpen ? drawerWidth : 0}px - ${rightOpen ? drawerWidth : 0}px)`,
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: leftOpen ? '10px' : -(drawerWidth - 20),
      marginRight: rightOpen ? '10px' : -(drawerWidth - 20),
      width: `calc(100% - ${leftOpen ? drawerWidth : 0}px - ${rightOpen ? drawerWidth : 0}px)`,
      padding: '16px',
    },
    ...(leftOpen && {
      marginLeft: 0,
      width: `calc(100% - ${drawerWidth}px - ${rightOpen ? drawerWidth : 0}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    }),
    ...(!leftOpen && {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px - ${rightOpen ? drawerWidth : 0}px)`,
    }),
    ...(rightOpen && {
      marginRight: 0,
      width: `calc(100% - ${leftOpen ? drawerWidth : 0}px - ${drawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginRight: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '10px',
      },
    }),
    ...(!rightOpen && {
      marginRight: -(drawerWidth - 20),
      width: `calc(100% - ${leftOpen ? drawerWidth : 0}px - ${drawerWidth}px)`,
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const NewLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  const location = useLocation();
  const { trackPage, triggerNavigationEvent } = useNavigationEvent();

  const dispatch = useDispatch();
  const { drawerOpen, drawerOpenRight } = useSelector((state) => state.menu);
  const { container } = useConfig();
  const { onChangeMenuType } = useConfig();

  const matched = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isDarkMode = () => {
    if (matched === true) {
      onChangeMenuType('dark');
    } else {
      onChangeMenuType('light');
    }
  };

  const checkPathInNavigation = (navObject, currentPath) => {
    // console.log(currentPath)
    for (let item of navObject.items) {
      if (item.type === 'item' && item.url === currentPath) {
        return true;
      }

      if (item.type === 'group' || item.type === 'collapse') {
        const inChildren = checkPathInNavigation({ items: item.children }, currentPath);
        if (inChildren) {
          return true;
        }
      }
    }
    return false;
  };

  // checks if the url is in navigation
  // if the menu items are in navigation, we can show the breadcrumbs that come from the menu
  // otherwise we need to add breadcrumbs manually
  const [showNavBreadcrumbs, setShowNavBreadcrumbs] = useState(false);


  useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isDarkMode();
    const isPathInNavigation = checkPathInNavigation(navigation, location.pathname);
    setShowNavBreadcrumbs(isPathInNavigation);
    // setShowNavBreadcrumbs(true);
  }, [matchDownMd, location.pathname]);

  const header = useMemo(
    () => (
      <Toolbar>
        <NewHeader />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: (drawerOpen || drawerOpenRight) ? theme.transitions.create('width') : 'none'
        }}
      >
        {header}
      </AppBar>

      {/* drawer */}
      <NewSidebar />

      {/* main content */}
      <Main
        theme={theme}
        leftOpen={drawerOpen}   // State for the left sidebar
        rightOpen={drawerOpenRight}
      >
        {/* breadcrumb */}
        {showNavBreadcrumbs && container && (
          <Container maxWidth="lg">
            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
            <Outlet />
          </Container>
        )}
        {showNavBreadcrumbs && !container && (
          <>
            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
            <Outlet />
          </>
        )}
        {/* Breadcrumbs added from the template for now */}
        {!showNavBreadcrumbs && container && (
          <Container maxWidth="lg">
            <Outlet />
          </Container>
        )}
        {!showNavBreadcrumbs && !container && (
          <>
            <Outlet />
          </>
        )}
      </Main>

      {/* drawer */}
      <RightSidebar />
    </Box>
  );
};

export default NewLayout;
